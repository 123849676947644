
import { defineComponent } from 'vue'
import FaqBlock from '@/components/shared/templates/FaqBlock.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    FaqBlock,
    TmExpansion,
  },
  setup() {
    const titles = [
      'What is the tickets feature?',
      'Why should I use tickets in Textmagic?',
      'Can I create a new ticket from chat?',
      'Will you automatically create a ticket when I receive an email from someone?',
      'I have multiple projects. Can I manage them all?',
    ]

    return {
      titles,
    }
  },
})
