
import { computed, defineComponent, watch } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { useModes } from '@/compositions/modes'
import { useRouter } from 'vue-router'
import TicketsFaq from '@/components/pages/tickets/TicketsFaq.vue'
import GettingStartedBlock from '@/components/pages/gettingStarted/GettingStartedBlock.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useTicketsGettingStarted } from '@/compositions/tickets/useTicketsGettingsStarted'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  components: {
    TicketsFaq,
    TmTooltip,
    PageContent,
    GettingStartedBlock,
    TmButton,
  },
  setup() {
    const router = useRouter()
    const { isMdMax } = useBreakpoints()
    const { isEmptyHalfMode } = useModes()
    const { startSimulateTicket, tooltipState, getOpenLink } = useTicketsGettingStarted()

    const isMobileTicketSimulated = computed(() => isMdMax.value && tooltipState.value)
    const buttonContentComputed = computed(() => ({
      text: isMobileTicketSimulated.value ? 'Check simulated ticket' : 'Simulate new ticket',
      icon: isMobileTicketSimulated.value ? 'visibility' : 'email',
    }))

    const handleClickSimulate = () => {
      if (isMobileTicketSimulated.value) {
        router.push(getOpenLink.value.route!)
      } else {
        startSimulateTicket()
      }
    }
    watch(() => isEmptyHalfMode.value, () => {
      router.push({ name: 'base.tickets.main.index' })
    })
    return {
      isMdMax,
      startSimulateTicket,
      handleClickSimulate,
      buttonContentComputed,
      tooltipState,
      isMobileTicketSimulated,
    }
  },
})
